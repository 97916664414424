<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <div class="pb-5 col-span-2">
          <legend class="text-base font-medium text-gray-900">
            Location
          </legend>
          <p class="text-sm text-gray-500">
            PSC
          </p>
        </div>
        <asom-form-field
          label="Event Name"
          required
          :state="inputStates('formData.eventName')"
          error="Event Name is required"
        >
          <asom-input-text
            type="text"
            max="24"
            min="0"
            v-model="formData.eventName"
            :state="inputStates('formData.eventName')"
          />
        </asom-form-field>
        <asom-form-field
          label="Date"
          required
          error="Select today or future date"
          :state="inputStates('formData.date')"
        >
          <asom-input-date
            :state="inputStates('formData.date')"
            v-model="formData.date"
            :min-date="new Date()"
          />
        </asom-form-field>
        <asom-form-field
          label="No. of Tickets"
          required
          :state="inputStates('formData.noOfTickets')"
          error="No. of Tickets is required"
        >
          <asom-input-text
            type="number"
            max="24"
            min="0"
            v-model="formData.noOfTickets"
            :state="inputStates('formData.noOfTickets')"
          />
        </asom-form-field>
        <asom-form-field
          label="Ticket Value"
          required
          :state="inputStates('formData.ticketValue')"
          error="Ticket Value is required"
        >
          <asom-input-text
            type="number"
            max="24"
            min="0"
            v-model="formData.ticketValue"
            :state="inputStates('formData.ticketValue')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push('/cash-mgnt/psc/preencoded-ticket')"
          />
          <asom-button
            text="Provision Tickets"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
    <asom-modal
      title="Create Event (Pre-encoded Ticket)"
      v-model="showModal"
      :dismissible="false"
    >
      <p>
        This will create a {{ formData.eventName }} event with
        {{ formData.noOfTickets }} tickets.
      </p>
      <p>Each ticket will be worth ${{ formData.ticketValue }}</p>
      <p class="pt-4">Event will be on ({{ eventDate }})</p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, minValue } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createEvent } from "../../../../services/cashManagement.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import moment from "moment";

export default {
  name: "CreateEvent",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        eventName: null,
        date: new Date(),
        noOfTickets: null,
        ticketValue: null,
      },
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  validations() {
    return {
      formData: {
        eventName: { required },
        ticketValue: { required },
        noOfTickets: { required },
        date: {
          required,
          minValue: minValue(new Date().setHours(0, 0, 0, 0)),
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      userStationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    eventDate() {
      return moment(this.formData.date, "DD/MM/YYYY").format("DD/MM/YYYY");
    },
  },
  methods: {
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createEvent({
        PSCId: this.pscId,
        stationId: this.userStationId,
        lineId: this.lineId,
        eventName: this.formData.eventName,
        noOfTickets: this.formData.noOfTickets,
        ticketValue: this.formData.ticketValue,
        startDate: parseDateTime(this.formData.date, false),
        endDate: parseDateTime(this.formData.date, false),
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "Pre Encoded Ticket Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
